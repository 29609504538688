<template>
    <div class="right_container">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span style="float: left;font-size:30px" shadow="hover"><b>&emsp;{{$t('message.personcenter.pointsnumber')}}：{{currentpoints}}</b></span>
          <el-popover
              placement="top-start"
              title="提示"
              :width="25"
              trigger="hover"
              content="1元兑换100积分"
          >
            <template #reference>
              <el-button circle icon="el-icon-question"  size="mini" />

            </template>
          </el-popover>

        </div>
        <div>
          <ul class="msg-box">
            <li>
              <el-row>

                <h4 style="float: left;margin-bottom: 20px;">{{$t('message.personcenter.pointsnumber')}}</h4>
              </el-row>
              <el-row>
                <el-radio-group v-model="amountVal2" @change="amountChange2" size="small">
                  <el-radio border :label="''+ 30">{{$t('message.personcenter.recharge')}}30</el-radio>
                  <el-radio border :label="''+ 50">{{$t('message.personcenter.recharge')}}50</el-radio>
                  <el-radio border :label="''+ 100">{{$t('message.personcenter.recharge')}}100</el-radio>
                  <el-radio border :label="''+ 200">{{$t('message.personcenter.recharge')}}200</el-radio>
                  <!--<el-radio border :label="''+ 500">{{$t('message.personcenter.recharge')}}500</el-radio>-->
                  <el-radio border :label="''">{{$t('message.personcenter.customized')}}</el-radio>
                </el-radio-group>
              </el-row>
            </li>
            <li>
              <el-row>
                <h4 style="float: left;margin-bottom: 15px;">{{$t('message.personcenter.pointsnumber')}}</h4>
              </el-row>
              <el-row>
                <el-input :disabled="jifendisabled" clearable v-model="chargeForm.totalEarningPoints"  :placeholder="$t('message.personcenter.please_number')" style="width: 500px;"></el-input>
              </el-row>
            </li>
            <li>
              <el-row>
                <h4 style="float: left;margin-bottom: 15px;">{{$t('message.personcenter.paymentway')}}</h4>
              </el-row>
              <el-row>
                <el-radio-group v-model="jifenParams.paymentType" @change="paymentTypeChange2" style="margin-left:40px;height: 100px;">
<!--                  <el-row style="margin-bottom: 10px">-->
<!--                    <el-radio  :label="''+ 0">{{$t('message.personcenter.purse')}}</el-radio>-->
<!--                  </el-row>-->
                  <el-row style="margin-bottom: 10px">
                    <el-radio  :label="''+ 1"><i class="iconfont icon-weixin"></i>{{$t('message.personcenter.wechat')}}</el-radio>
                  </el-row>
                  <el-row style="margin-bottom: 10px">
                    <el-radio  :label="''+ 2"><i class="iconfont icon-zhifubao"></i>{{$t('message.personcenter.alipay')}}</el-radio>
                  </el-row>
<!--                  <el-row style="margin-bottom: 10px">-->
<!--                    <el-radio  :label="''+ 3"><i class="el-icon-bank-card"></i>{{$t('message.personcenter.bankcard')}}</el-radio>-->
<!--                  </el-row>-->
                </el-radio-group>
              </el-row>
              <el-row>
                <el-col :span="20"></el-col>
                <el-col :span="4">
                  <el-button
                      style="float: right"
                      type="primary"
                      size="medium"
                      @click="gotoPay"
                  >
                    {{$t('message.personcenter.gotopay')}}
                  </el-button>
                </el-col>
              </el-row>

            </li>

          </ul>
        </div>
      </el-card>
<!--    <el-main>-->
<!--      <el-tabs v-model="activeName" @tab-click="handleClick">-->
<!--        <el-tab-pane :label="$t('message.personcenter.walletrecharge')" name="first">-->
<!--          <el-card  class="box-card">-->
<!--          <div slot="header" class="clearfix">-->
<!--            <span style="float: left;font-size:30px"><b>&emsp;{{$t('message.personcenter.walletamount')}}：{{}}{{$t('message.personcenter.yuan')}}</b></span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <ul class="msg-box">-->
<!--              <li>-->
<!--                <el-row>-->
<!--                  <h4 style="float: left;margin-bottom: 15px;">{{$t('message.personcenter.top_upamount')}}</h4>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-radio-group v-model="amountVal" @change="amountChange" size="small">-->
<!--                    <el-radio border :label="''+ 30">{{$t('message.personcenter.recharge')}}30</el-radio>-->
<!--                    <el-radio border :label="''+ 50">{{$t('message.personcenter.recharge')}}50</el-radio>-->
<!--                    <el-radio border :label="''+ 100">{{$t('message.personcenter.recharge')}}100</el-radio>-->
<!--                    <el-radio border :label="''+ 200">{{$t('message.personcenter.recharge')}}200</el-radio>-->
<!--                    &lt;!&ndash;<el-radio border :label="''+ 500">{{$t('message.personcenter.recharge')}}500</el-radio>&ndash;&gt;-->
<!--                    <el-radio border :label="''">{{$t('message.personcenter.customized')}}</el-radio>-->
<!--                  </el-radio-group>-->
<!--                </el-row>-->
<!--              </li>-->
<!--              <li>-->
<!--                <el-row>-->
<!--                  <h4 style="float: left;margin-bottom: 15px;">{{$t('message.personcenter.top_upamount')}}</h4>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-input :disabled="rechargedisabled" clearable v-model="rechargeParams.totalAmt" :placeholder="$t('message.personcenter.please_account')" style="width: 500px;"></el-input>-->
<!--                </el-row>-->
<!--              </li>-->
<!--              <li>-->
<!--                <el-row>-->
<!--                  <h4 style="float: left;margin-bottom: 15px;">{{$t('message.personcenter.paymentway')}}</h4>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-radio-group v-model="rechargeParams.paymentType" @change="paymentTypeChange" style="margin-left:40px;height: 100px;">-->
<!--                    <el-row  style="margin-bottom: 10px">-->
<!--                      <el-radio  :label="''+ 0"><i class="iconfont icon-weixin"></i>{{$t('message.personcenter.wechat')}}</el-radio>-->
<!--                    </el-row>-->
<!--                    <el-row  style="margin-bottom: 10px">-->
<!--                      <el-radio  :label="''+ 1"><i class="iconfont icon-zhifubao"></i>{{$t('message.personcenter.alipay')}}</el-radio>-->
<!--                    </el-row>-->
<!--                    <el-row  style="margin-bottom: 10px">-->
<!--                      <el-radio  :label="''+ 2"><i class="el-icon-bank-card"></i>{{$t('message.personcenter.bankcard')}}</el-radio>-->
<!--                    </el-row>-->
<!--                  </el-radio-group>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-col :span="20"></el-col>-->
<!--                  <el-col :span="4">-->
<!--                    <el-button-->
<!--                        style="float: right"-->
<!--                        type="primary"-->
<!--                        size="medium"-->
<!--                    >-->
<!--                      {{$t('message.personcenter.gotopay')}}-->
<!--                    </el-button>-->
<!--                  </el-col>-->
<!--                </el-row>-->


<!--              </li>-->

<!--            </ul>-->
<!--            &lt;!&ndash;<div style="text-align: center; margin-top: 30px;">&ndash;&gt;-->
<!--            &lt;!&ndash;<el-button type="primary" @click="surePay">确认支付</el-button>&ndash;&gt;-->
<!--            &lt;!&ndash;</div>&ndash;&gt;-->
<!--          </div>-->
<!--        </el-card>-->
<!--        </el-tab-pane>-->
<!--        <el-tab-pane :label="$t('message.personcenter.integralrecharge')" name="second">-->
<!--          <el-card class="box-card">-->
<!--          <div slot="header" class="clearfix">-->
<!--            <span style="float: left;font-size:30px" shadow="hover"><b>&emsp;{{$t('message.personcenter.pointsnumber')}}：{{currentpoints}}</b></span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <ul class="msg-box">-->
<!--              &lt;!&ndash;<li>&ndash;&gt;-->
<!--              &lt;!&ndash;<h4>我要充值</h4>&ndash;&gt;-->
<!--              &lt;!&ndash;</li>&ndash;&gt;-->
<!--              <li>-->
<!--                <el-row>-->
<!--                  <h4 style="float: left;margin-bottom: 20px;">{{$t('message.personcenter.pointsnumber')}}</h4>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-radio-group v-model="amountVal2" @change="amountChange2" size="small">-->
<!--                    <el-radio border :label="''+ 30">{{$t('message.personcenter.recharge')}}30</el-radio>-->
<!--                    <el-radio border :label="''+ 50">{{$t('message.personcenter.recharge')}}50</el-radio>-->
<!--                    <el-radio border :label="''+ 100">{{$t('message.personcenter.recharge')}}100</el-radio>-->
<!--                    <el-radio border :label="''+ 200">{{$t('message.personcenter.recharge')}}200</el-radio>-->
<!--                    &lt;!&ndash;<el-radio border :label="''+ 500">{{$t('message.personcenter.recharge')}}500</el-radio>&ndash;&gt;-->
<!--                    <el-radio border :label="''">{{$t('message.personcenter.customized')}}</el-radio>-->
<!--                  </el-radio-group>-->
<!--                </el-row>-->
<!--              </li>-->
<!--              <li>-->
<!--                <el-row>-->
<!--                  <h4 style="float: left;margin-bottom: 15px;">{{$t('message.personcenter.pointsnumber')}}</h4>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-input :disabled="jifendisabled" clearable v-model="chargeForm.totalEarningPoints"  :placeholder="$t('message.personcenter.please_number')" style="width: 500px;"></el-input>-->
<!--                </el-row>-->
<!--              </li>-->
<!--              <li>-->
<!--                <el-row>-->
<!--                  <h4 style="float: left;margin-bottom: 15px;">{{$t('message.personcenter.paymentway')}}</h4>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-radio-group v-model="jifenParams.paymentType" @change="paymentTypeChange2" style="margin-left:40px;height: 100px;">-->
<!--                    <el-row style="margin-bottom: 10px">-->
<!--                      <el-radio  :label="''+ 0">{{$t('message.personcenter.purse')}}</el-radio>-->
<!--                    </el-row>-->
<!--                    <el-row style="margin-bottom: 10px">-->
<!--                      <el-radio  :label="''+ 1"><i class="iconfont icon-weixin"></i>{{$t('message.personcenter.wechat')}}</el-radio>-->
<!--                    </el-row>-->
<!--                    <el-row style="margin-bottom: 10px">-->
<!--                      <el-radio  :label="''+ 2"><i class="iconfont icon-zhifubao"></i>{{$t('message.personcenter.alipay')}}</el-radio>-->
<!--                    </el-row>-->
<!--                    <el-row style="margin-bottom: 10px">-->
<!--                      <el-radio  :label="''+ 3"><i class="el-icon-bank-card"></i>{{$t('message.personcenter.bankcard')}}</el-radio>-->
<!--                    </el-row>-->
<!--                  </el-radio-group>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                  <el-col :span="20"></el-col>-->
<!--                  <el-col :span="4">-->
<!--                    <el-button-->
<!--                        style="float: right"-->
<!--                        type="primary"-->
<!--                        size="medium"-->
<!--                        @click="gotoPay"-->
<!--                    >-->
<!--                      {{$t('message.personcenter.gotopay')}}-->
<!--                    </el-button>-->
<!--                  </el-col>-->
<!--                </el-row>-->

<!--              </li>-->

<!--            </ul>-->
<!--          </div>-->
<!--        </el-card>-->
<!--        </el-tab-pane>-->
<!--      </el-tabs>-->
<!--    </el-main>-->
    </div>
</template>

<script>
  import {pointsCharge,getMypoints} from "../../api/integral";

  export default {
    name: "Deposit",
    data(){
      return{
        chargeForm:{
          totalEarningPoints:'',
          customerId:'',
          payAmount:'',
          chargeType:'',
        },
        currentmoney:'',
        currentpoints:'',
        activeName:'first',
        amountVal: '',
        amountVal2: '',
        qianbaoisShow:true,
        jifenisShow:true,
        dialogVisible:false,
        rechargedisabled: false,
        jifendisabled: false,
        rechargeParams: {
          totalAmt: '', //金额
          paymentType: "0", //支付方式[0:微信,1:支付宝,2:余额,3:活动]
          transType: "0" //交易类型[0:充值,1:消费]
        },
        jifenParams: {
          totalAmt: '', //金额
          paymentType: "0", //支付方式[0:微信,1:支付宝,2:余额,3:活动]
          transType: "0" //交易类型[0:充值,1:消费]
        },
        text: '',
        input1: '',
        input2: '',
        input3: '',
        input4: '',
        input5: '',
        input6: '',
        input7: '',
        input8: '',
        input9: '',
        circleUrl:
          'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
        sizeList: ['large'],
        tableData: [
          {
            name:window.vm.$t('message.personcenter.walletamount'),
            count:'50'
          },
          {
            name:window.vm.$t('message.personcenter.pointsnumber'),
            count:'100'
          },
        ],
      }
    },
    methods:{
      paymentTypeChange2(val){
        if(val ==1)
          this.chargeForm.chargeType = 2
        else if(val == 2)
          this.chargeForm.chargeType = 1
      },
      gotoPay(){
        console.log(this.chargeForm.totalEarningPoints)
        console.log(document.forms[0])
        this.chargeForm.customerId=localStorage.getItem('customerId')
        if(this.chargeForm.totalEarningPoints==''){
          this.$message.warning('请输入积分数量')
        } if(this.chargeForm.totalEarningPoints!=''&&this.chargeForm.chargeType==''){
          this.$message.warning('请选择付款方式')
        }

        if(this.chargeForm.totalEarningPoints!=''&&this.chargeForm.chargeType!=''){
          this.chargeForm.payAmount=this.chargeForm.totalEarningPoints/100
          // this.$router.push({name:'weixin',params:{chargeForm:JSON.stringify(this.chargeForm)}})
          if(this.chargeForm.chargeType == 2){
            this.$router.push({name:'weixin',params:{chargeForm:JSON.stringify(this.chargeForm)}})
          }
          if(this.chargeForm.chargeType == 1){
            pointsCharge(this.chargeForm).then(response=>{
              const div = document.createElement('div');
              div.innerHTML = response.data; // html code
              document.body.appendChild(div);
              document.forms[0].submit();
            })
          }
        }
      },
      handleClick(row){
        if(row.name == window.vm.$t('message.personcenter.walletamount')){
          this.qianbaoisShow = true;
          this.jifenisShow = false
        }
        else {
          this.qianbaoisShow = false;
          this.jifenisShow = true
        }
      },
      amountChange(val) {
        this.rechargeParams.totalAmt = val;
        if (val == '') {
          this.rechargedisabled = false
        } else {
          this.rechargedisabled = true
        }
      },
      amountChange2(val) {
        this.chargeForm.totalEarningPoints = val;
        if (val == '') {
          this.jifendisabled = false
        } else {
          this.jifendisabled = true
        }
      },
      getPoints(){
        getMypoints().then(response=>{
          this.currentpoints = response.data
        })
      }
    },
    created(){
      this.getPoints()
    }
  };
</script>

<style lang="scss">
    .right_container{
        width:100% ;
        height: 100%;
      .el-card{
        float:left;
        margin-right:20px;
        width:65%;
        height:100%;
      }
        .msg-box > li {
            list-style: none;
            border-bottom: 1px solid #c5c5c5;
            padding: 20px 10px;
        }
    }
    .el-main {
        height: 100%;
        line-height: 36px;
        text-align: center;
        .el-card{
            float:left;
            margin-right:20px;
            width:65%;
            height:100%;
        }
        //background-color: #e9eef3;
    }

    .demo-basic--circle {
        align: 'center';
        margin-top: 30px;
        margin-left: 150px;
    }
    .block {
        width: 100%;
        margin-left: 25px;
        font-weight: bold;
    }
    .text {
        font-size: 14px;
    }
    .item {
        margin-bottom: 18px;
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: '';
    }
    .clearfix:after {
        clear: both;
    }

    .box-card {
        margin-bottom: 15px;
        width: 100%;
        border-radius: 20px;
    }
</style>
